import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB_Vc2TokUXUl2wkmhJVbMiZeS5v-0XDJw",
  authDomain: "jesus2-7fdf2.firebaseapp.com",
  projectId: "jesus2-7fdf2",
  storageBucket: "jesus2-7fdf2.appspot.com",
  messagingSenderId: "482675113385",
  appId: "1:482675113385:web:ec713539ad1e4f0ceb98b8",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export { app, db, auth, storage };
