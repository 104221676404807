import React, { useEffect, useState } from "react";
import { db } from "./firebase"; // Importez l'instance Firestore depuis votre fichier firebase.js
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import bg from "./bg.png";
import "./Landing.css";

import { Close, Done } from "@mui/icons-material";

function Landing() {
  const [email, setEmail] = useState("");
  const [submittedEmail, setSubmittedEmail] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonClicks, setButtonClicks] = useState(0);
  const [count, setCount] = useState(23500); // Initial count

  const handleButtonClick = async () => {
    setButtonClicks(buttonClicks + 1);
    try {
      const buttonClicksRef = collection(db, "buttonClicks");
      await addDoc(buttonClicksRef, {
        timestamp: serverTimestamp(),
        clicks: buttonClicks + 1,
      });
    } catch (error) {}
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (isValidEmail(email)) {
      try {
        await addDoc(collection(db, "emails"), {
          email: email,
          timestamp: new Date(),
        });

        setSubmittedEmail(email);
        setEmail("");
      } catch (error) {
        console.error(
          "Erreur lors de l'enregistrement dans la base de données :",
          error
        );
      }
    }
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIncrement = Math.floor(Math.random() * 12) + 1;
      const newCount = count + randomIncrement;
      const updatedCount = Math.min(Math.max(newCount, 190500), 320000);
      setCount(updatedCount);
    }, 2000);

    return () => clearInterval(interval);
  }, [count]);

  return (
    <div className="landing">
      {isLoading ? (
        <div className="loading__skeleton">
          <div className="loading__skeleton__heading"></div>
          <div className="loading__skeleton__text"></div>
          <div className="loading__skeleton__input"></div>
          <div className="loading__skeleton__button"></div>
          <div className="loading__skeleton__image"></div>
        </div>
      ) : (
        <div className="landingpage">
          <div className="landingpage__container1">
            <div className="hook">
              <p>
                Parlez à <span className="text__colour">Jésus </span>
                <br /> n'importe où, <br />
                n'importe quand, <br />
                via WhatsApp
              </p>
            </div>

            <div className="value__proposition">
              <p>Explorez votre foi, à votre rythme</p>
            </div>

            <form onSubmit={handleFormSubmit}>
              <div className="form">
                <div className="form__label">
                  <p> Saisissez votre adrese e-mail pour commencer</p>
                </div>

                <input
                  type="email"
                  placeholder="@ e-mail"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>

              <div className="cta">
                <button onClick={handleButtonClick} type="submit">
                  Démarrer la conversation
                </button>
              </div>
            </form>

            <div className="connected__dot">
              <div className="connected__dot__left">
                <div className="connected__dot__round"></div>
              </div>
              <div className="connected__dot__right">
                <div className="counter">
                  <p>{count.toLocaleString("fr-FR")} conversations en ce moment</p>
                </div>
              </div>
            </div>

            <div className="notification__process">
              {submittedEmail && isPopupOpen && (
                <div className="toaster">
                  <div className="feedback__user">
                    <h2>👋 Un message à été envoyé sur votre boîte mail </h2>
                    <p>
                      Vous la recevrez à cette adresse e-mail : {submittedEmail}{" "}
                      ✝️ 
                    </p>
                    <Close
                      className="close-icon"
                      onClick={() => setIsPopupOpen(false)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="big__title">
            <p>Parlez avec Jésus, <br/>
              24/7 sur WhatsApp</p>
          </div>
         

          <div className="credits">
            <p>2023 v1.2</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Landing;
